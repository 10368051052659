import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faXTwitter, faLinkedinIn, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import MapsFullMap from "./MapsFullMap";
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

export default function Footer() {

  const whatsappNumber = "9309234245"; 
  const whatsappMessage = "Hello, I would like to know more about your services.";

  return (
    <footer className=" px-4 pt-4 " >
      <div className="mx-auto">
          <div className='flex flex-col lg:flex-row  md:flex-row border border-black p-8 rounded-lg'>
            <div className="flex flex-wrap justify-between items-start gap-4">
              {/* Logo and Navigation */}
              <div className=" ">
                <img src="./ashdip-logo.png" alt="Ashdip IT Solutions" className="mb-10 w-44" />
                <nav>
                    <ul className="space-y-2 font-bold">
                      <li>
                        <Link to="/" className="text-blue-600 hover:underline">Home</Link>
                      </li>
                      <li>
                        <Link to="/about" className="text-blue-600 hover:underline">About</Link>
                      </li>
                      <li>
                        <Link to="/services" className="text-blue-600 hover:underline">Services</Link>
                      </li>
                      <li>
                        <Link to="/career" className="text-blue-600 hover:underline">Career</Link>
                      </li>
                    </ul>
                  </nav>
              </div>

              {/* Contact Information */}
              <div className="">
                <div className='pe-2'>
                  <h3 className="font-bold mb-2 text-xl">Address</h3>
                  <p className="mb-4 text-sm select-all">
                  <FontAwesomeIcon icon={faLocationDot} className='pe-1'/>
                     P. J. Chambers, 304/2, Nehru Nagar Rd, Atma Nagar, Kharalwadi, Pimpri Colony, Pune, Pimpri-Chinchwad, Maharashtra 411018
                  </p>
                </div>
                <div className='pb-4'>
                  <h3 className="font-bold mb-2 text-xl">Contact:</h3>
                  <p className="mb-4 text-sm text-nowrap">
                    <FontAwesomeIcon icon={faPhone} className='pe-1'/> 9309234245<br />
                    <FontAwesomeIcon icon={faEnvelope} className='pe-1'/>info@ashdipitsolutions.in
                  </p>
                </div>
                <div className="flex space-x-4 mb-3">
                  <a href="https://www.facebook.com/share/Gdo7TDLddQnY8tAF/?mibextid=qi2Omg" className="text-gray-600 hover:text-blue-600">
                    <FontAwesomeIcon icon={faFacebookF} size="xl"/>
                  </a>
                  <a href="https://www.instagram.com/ashdipit?igsh=MWV3cmZpbGc5cGRwYw==" className="text-gray-600 hover:text-pink-600">
                    <FontAwesomeIcon icon={faInstagram} size="xl"/>
                  </a>
                  <a href="https://x.com/Ashdipit?s=09" className="text-gray-600 hover:text-black">
                    <FontAwesomeIcon icon={faXTwitter} size="xl"/>
                  </a>
                  <a href="https://www.linkedin.com/in/ashok-sonawane-81a693183?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" className="text-gray-600 hover:text-blue-700">
                    <FontAwesomeIcon icon={faLinkedinIn} size="xl"/>
                  </a>
                  <a href="https://www.youtube.com" className="text-gray-600 hover:text-red-600">
                    <FontAwesomeIcon icon={faYoutube} size="xl"/>
                  </a>
                  <a href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`} 
                    target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-green-500">
                    <FontAwesomeIcon icon={faWhatsapp} size="xl"/>
                  </a>
                </div>
              </div>
            </div>

              {/* Locate Us */}
              <div className="h-71 pt-3 md:border-0 border-t md:pt-0"   >
                  <h2 className="text-xl font-bold mb-4">LOCATE US</h2>
                  <div className=""> {/* 16:9 Aspect Ratio */}
                  <MapsFullMap />
                  </div>
              </div>
        </div>

        {/* Copyright and Additional Links */}
        <div className="mt-2 mb-2 text-sm text-gray-600 flex flex-wrap justify-between ">
          <p>© 2024 Ashdip It Solutions</p>
          <span className="space-x-4">
            <Link to="/privacy-policy" className="underline text-black">Privacy Policy</Link>
            <Link to="/terms-of-service" className="underline text-black">Terms of Service</Link>
            <Link to="/cookies-settings" className="underline text-black">Cookies Settings</Link>
          </span>
          <p>Designed & Develop @AshdipITSolutions</p>
        </div>
      </div>
    </footer>
  )
}