import { useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faXTwitter, faLinkedin, faYoutube, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { ChevronDown } from 'lucide-react';
import { Link } from "react-router-dom";
// import { Link as ScrollLink } from "react-scroll";

const whatsappNumber = "9309234245";
const whatsappMessage = "Hello, I'm interested in your services!"; 

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="flex items-center justify-between p-4 w-100 h-25" style={{ backgroundColor: '#f1e9e973' }}>
      <div className="flex items-center">
        <img src="..\ashdip-logo.png" alt="Ashdip logo" className="mr-2 w-44"  />
      </div>

      <div className="flex items-center space-x-4">
        {/* Free Website Audit Button */}
        <Button variant="outline" className="text-decoration-underline  text-black " style={{fontSize:'0.75rem'}}>Free Website Audit</Button>

        {/* Options Menu */}
        <div className="relative">
          <button
            className="inline-flex items-center gap-2 rounded-lg bg-gray-800 py-2.5 px-4 text-sm font-semibold text-white shadow-inner shadow-white/10"
            onClick={toggleMenu}
          >
            Home
            <ChevronDown className={`h-4 w-4 transition-transform ${isMenuOpen ? 'rotate-180' : ''}`} />
          </button>
        </div>

          {/* Dropdown Menu */}
          {isMenuOpen && (
            <div className="absolute top-16 right-5 mt-2 w-64 bg-black text-white shadow-lg rounded-lg z-10 p-4">
              <nav className="space-y-4 ">
                <Link to="/adminlogin" className="block hover:text-gray-300 text-white border-b pb-2 fw-bold">Admin</Link>
                
                <Link to="/career" className="block hover:text-gray-300 text-white fw-bold border-b pb-2 ">Career</Link>
                
                <Link to="/services" className="block hover:text-gray-300 text-white fw-bold border-b pb-2">Our Services</Link>

                <Link to="/contactus" className="block hover:text-gray-300 text-white fw-bold border-b pb-2">Contact Us</Link>

                <Link to="/about" className="block hover:text-gray-300 text-white fw-bold border-b pb-2">About</Link>
                
                <Link to="/blog" className="block hover:text-gray-300 text-white fw-bold border-b pb-2">Blog</Link>

                {/* Social Media Links */}
                <div className="pt-2">
                  <h3 className="text-lg font-semibold mb-2">Social Links</h3>
                  <div className="flex space-x-4">
                    <a href="https://www.facebook.com/share/Gdo7TDLddQnY8tAF/" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                      <FontAwesomeIcon icon={faFacebook} className="h-6 w-6" />
                    </a>
                    <a href="https://www.instagram.com/ashdipit" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                      <FontAwesomeIcon icon={faInstagram} className="h-6 w-6" />
                    </a>
                    <a href="https://x.com/Ashdipit" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                      <FontAwesomeIcon icon={faXTwitter} className="h-6 w-6" />
                    </a>
                    <a href="https://www.linkedin.com/in/ashok-sonawane-81a693183" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                      <FontAwesomeIcon icon={faLinkedin} className="h-6 w-6" />
                    </a>
                    <a href="/" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
                      <FontAwesomeIcon icon={faYoutube} className="h-6 w-6" />
                    </a>
                    <a
                      href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="WhatsApp"
                    >
                      <FontAwesomeIcon icon={faWhatsapp} className="h-6 w-6" />
                    </a>
                  </div>
                </div>
              </nav>
            </div>
          )}
      </div>
    </header>
  );
}
