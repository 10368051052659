import styles from "./maps-full-map.module.css";

const MapsFullMap = ({ className = "" }) => {

  const address = "P. J. Chambers, 304/2, Nehru Nagar Rd, Atma Nagar, Kharalwadi, Pimpri Colony, Pune, Pimpri-Chinchwad, Maharashtra 411018";
  const encodedAddress = encodeURIComponent(address);
  const mapSrc = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.6165971349714!2d73.81407247496372!3d18.618843387305448!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9e07bc6c58b%3A0x13c48de7415a47b7!2s${encodedAddress}!5e0!3m2!1sen!2sin!4v1697018462523!5m2!1sen!2sin&output=embed&iwloc=near&t=m&z=14&layer=t`;


  return (
    <div className={[styles.mapsFullMap, className].join(" ")}>
      <div className="">
        <iframe
        title="Ashdip IT Solutions"
        src={mapSrc}
        width="500"
        height="300"
        style={{ border: '0' }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        />

      </div>
    </div>
  );
};

export default MapsFullMap;
